@import "variable";

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .header-section-left {
    display: flex;
  }

  .header-section-right {
    .edit-icon {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .title-page {
    text-transform: uppercase;
    font-weight: 600;
    font-size: $heading-h6;
    line-height: 32px;
    margin: 0;
    color: $primary-alternative-dark;
  }
}

.scrollable-content {
  .header-section {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .sub-header-section {
    display: flex;
    align-items: center;

    .sub-header {
      margin: 0 0.5rem;
      font-weight: 600;
      font-size: 18px;
      color: $primary-alternative-dark;
    }

    .sub-header-action {
      font-size: 20px;
      margin: 0 1rem;
      cursor: pointer;
    }
  }

  overflow: auto;
}

.main-page {
  background: $white;
  position: absolute;
  left: 1rem;
  top: 0;
  right: 1rem;
  bottom: 1rem;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 0.97;

  .action-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bottom-action-section {
    border-top: 1px solid $action-disabled;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
  }

  .container-section {
    flex: 1 1 auto;
    margin-top: 1rem;
    position: relative;
  }
}

.table-empty-img {
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc((100vw - $left-menu-minimized-width) / 2.5);

  img {
    width: auto;
    max-width: 100px;
    margin-bottom: 18px;
  }
}

.layout-wrapper-static {
  .table-empty-img {
    left: calc((100vw - $left-menu-width) / 2.5) !important;
  }
}

.la-trash-alt {
  color: $error-alternative-dark !important;
}

.readonly-view {
  .readonly-label {
    font-size: 14px;
    color: $text-primary;
  }

  .readonly-content {
    color: $primary-alternative-dark;
    padding-top: 4px;
    font-weight: 400;
    overflow-wrap: anywhere;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

/* text style */
.text-info-alternative-dark {
  color: $info-alternative-dark !important;
}

.active-status {
  color: $success-alternative-dark;
}

.inactive-status {
  color: $error-alternative-dark;
}

.warning-status {
  color: $warning-alternative-dark;
}

.p-input-icon-right {
  & > em {
    margin-top: 0;
    transform: translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 20px;
  }

  width: 100%;
}

.chip-view--container {
  display: flex;
  flex-wrap: wrap;

  .chip-view--item {
    padding: 7px 10px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid $primary-main;
    color: $primary-alternative-dark;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.error-msg {
  position: relative;
  z-index: 1;
  background: $white;
}

.bg-primary-dark {
  background-color: $primary-alternative-dark;
}

.bg-success-dark {
  background-color: $success-alternative-dark;
}

.bg-error-dark {
  background-color: $error-alternative-dark;
}

.bg-primary-main {
  background-color: $primary-main;
}

.bg-success-alternative-dark {
  background-color: $success-alternative-dark;
}

.bg-primary-alternative-dark {
  background-color: $primary-alternative-dark;
}

.bg-info-alternative-dark {
  background-color: $info-alternative-dark;
}

.bg-warning-alternative-dark {
  background-color: $warning-alternative-dark;
}

.bg-warning-main {
  background-color: $warning-main;
}

.bg-other-divider {
  background-color: $other-divider;
}
.bg-action-selected {
  background-color: $action-selected;
}
