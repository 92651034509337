@import "src/assets/styles/variable";

.p-dropdown {
  border-radius: 8px;
  border-color: $other-divider;
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
      border-color: $info-main;
    }
    &:hover {
      border-color: $info-main;
    }
  }
  width: 100%;
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      &.p-highlight {
        color: $info-main;
        background-color: $primary-outlined-hover-background;
      }
    }
  }
}

.p-ripple {
  .p-ink {
    background: $info-outlined-hover-background;
  }
}

