
.p-inputtext {
  width: 100%;
  font-family: inherit;
  padding: 12.5px;
  color: $primary-alternative-dark;
  border-color: $other-divider;
  text-overflow: ellipsis;
  border-radius: 8px;

  &:enabled {
    &:focus {
      border-color: $info-main;
      box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
    }

    &:hover {
      border-color: $info-main;
    }
  }
}

.p-float-label {
  & > label {
    color: $text-primary;
    font-size: 14px !important;
  }

  .p-inputwrapper-focus {
    & ~ label {
      color: $info-main;
      font-size: 14px !important;
    }
  }

  .p-inputwrapper-filled {
    & ~ label {
      color: $text-primary;
      font-size: 14px !important;
    }
  }

  input {
    &:focus {
      & ~ label {
        color: $info-main;
        font-size: 14px !important;
      }
    }

    &.p-filled {
      & ~ label {
        color: $text-primary;
        font-size: 14px !important;
      }
    }
  }
}

.p-inputgroup {
  display: flex;

  .p-float-label {
    &:first-child {
      input {
        width: 100%;
      }
    }
  }

  .p-dropdown {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
  }

  .p-inputwrapper {
    width: 100%;

    .p-dropdown {
      width: 100%;
    }
  }

  &.left {
    flex-direction: row-reverse;

    .p-float-label {
      input {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
      }
    }

    .p-inputgroup-addon {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
    }

    .p-dropdown {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
  }

  .p-inputtext {
    width: 100%;
    font-family: inherit;
    padding: 13px 13px;
    color: $primary-alternative-dark;
    border-color: $other-divider;
    text-overflow: ellipsis;
    border-radius: 8px;

    &:enabled {
      &:focus {
        border-color: $info-main;
        box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
      }

      &:hover {
        border-color: $info-main;
      }
    }
  }

  .p-float-label {
    flex-direction: column;


    & > label {
      color: $primary-alternative-dark;
      font-size: 1rem !important;
    }

    .p-inputwrapper-focus {
      & ~ label {
        color: $info-main;
        font-size: 14px !important;
      }
    }

    .p-inputwrapper-filled {
      & ~ label {
        color: $text-primary;
        font-size: 14px !important;
      }
    }

    input {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;

      &:focus {
        & ~ label {
          color: $info-main;
          font-size: 14px !important;
        }
      }

      &.p-filled {
        & ~ label {
          color: $text-primary;
          font-size: 14px !important;
        }
      }
    }
  }
}

.p-inputgroup-addon {
  padding: 13px 13px;
  width: 65%;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
