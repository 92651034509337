.lp-icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: inline-block;
  background-color: $text-primary;
  mask-size: contain !important;
  mask-repeat: no-repeat !important;
  mask-position: center center !important;
  -webkit-mask-repeat: no-repeat !important;
  -webkit-mask-position: center center !important;
  -webkit-mask-size: contain !important;
}

.lp-action {
  @extend .lp-icon;
  mask: url('../../icons/lp-action.svg');
}

.lp-add {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/lp-add.svg');
}

.lp-pencil {
  @extend .lp-icon;
  mask: url('../../icons/lp-pencil.svg');
}

.lp-campaign {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/lp-campaign.svg');
}

.lp-campaign-add {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/lp-campaign-add.svg');
}

.lp-stop {
  @extend .lp-icon;
  mask: url('../../icons/lp-stop.svg');
}

.lp-caret-right {
  @extend .lp-icon;
  mask: url('../../icons/lp-caret-right.svg');
}

.lp-trash {
  @extend .lp-icon;
  mask: url('../../icons/lp-trash.svg');
}

.lp-customer {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/customer.svg');
}

.lp-download {
  @extend .lp-icon;
  mask: url('../../icons/download.svg');
}

.lp-call {
  @extend .lp-icon;
  mask: url('../../icons/call.svg');
}

.lp-sms {
  @extend .lp-icon;
  mask: url('../../icons/sms.svg');
}

.lp-email {
  @extend .lp-icon;
  mask: url('../../icons/email.svg');
}

.lp-product {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/lp-product.svg');
}

.lp-administration {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/lp-administration.svg');
}

.lp-pending {
  width: 22px;
  height: 22px;
  @extend .lp-icon;
  mask: url('../../icons/pending.svg');
}

.lp-todo {
  @extend .lp-icon;
  width: 22px;
  height: 22px;
  mask: url('../../icons/lp-todo.svg');
}

.lp-vector {
  @extend .lp-icon;
  mask: url('../../icons/lp-vector.svg');
}

.lp-vector-up {
  @extend .lp-icon;
  mask: url('../../icons/lp-vector-up.svg');
}

.lp-vector-down {
  @extend .lp-icon;
  mask: url('../../icons/lp-vector-down.svg');
}

.lp-inventory {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/lp-inventory.svg') !important;
}

.lp-calendar {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  mask: url('../../icons/calendar.svg');
}

.lp-import-inventory {
  @extend .lp-icon;
  mask: url('../../icons/lp-import-inventory.svg');
}

.lp-export-inventory {
  @extend .lp-icon;
  mask: url('../../icons/lp-export-inventory.svg');
}

.lp-clock {
  @extend .lp-icon;
  mask: url('../../icons/clock.svg');
}

.lp-check {
  @extend .lp-icon;
  mask: url('../../icons/lp-check.svg');
}


.lp-sales {
  @extend .lp-icon;
  mask: url('../../icons/lp-sales.svg');
}

.lp-close-sales {
  @extend .lp-icon;
  mask: url('../../icons/lp-close-sales.svg');
}


.lp-upload-file {
  @extend .lp-icon;
  mask: url('../../icons/lp-upload-file.svg');
}

.lp-upload-sample-file {
  @extend .lp-icon;
  mask: url('../../icons/lp-upload-sample-file.svg');
}

.lp-excel {
  @extend .lp-icon;
  mask: url('../../icons/lp-excel.svg');
}

.lp-headphone {
  @extend .lp-icon;
  mask: url('../../icons/headphone.svg');
}

.lp-transfer-user {
  @extend .lp-icon;
  mask: url('../../icons/transfer-user.svg');
}

.lp-transfer-cross-sell {
  @extend .lp-icon;
  mask: url('../../icons/transfer-cross-sell.svg');
}

.lp-number-one {
  @extend .lp-icon;
  mask: url('../../icons/lp-number-one.svg');
}

.lp-number-two {
  @extend .lp-icon;
  mask: url('../../icons/lp-number-two.svg');
}

.lp-lock {
  @extend .lp-icon;
  mask: url('../../icons/lock.svg');
}

.lp-unlock {
  @extend .lp-icon;
  mask: url('../../icons/unlock.svg');
}

.lp-convert {
  @extend .lp-icon;
  mask: url('../../icons/convert.svg');
}

.lp-convert-opp-sale {
  @extend .lp-icon;
  mask: url('../../icons/lp-opp-to-sale.svg');
}

.lp-doing {
  @extend .lp-icon;
  mask: url('../../icons/lp-doing.svg');
}

.lp-addon {
  @extend .lp-icon;
  mask: url('../../icons/lp-addon.svg');
}

.lp-cancel {
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-cancel.svg');
}

.lp-success-circle {
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-success-circle.svg');
}

.lp-file-upload-alt {
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-file-upload-alt.svg');
}

.lp-meeting {
  @extend .lp-icon;
  mask: url('../../icons/lp-meeting.svg');
}

.lp-chevron-up {
  @extend .lp-icon;
  mask: url('../../icons/chevron-up.svg');
}

.lp-chevron-down {
  @extend .lp-icon;
  mask: url('../../icons/chevron-down.svg');
}

.lp-angle-right {
  @extend .lp-icon;
  mask: url('../../icons/lp-angle-right.svg');
}

.lp-star {
  @extend .lp-icon;
  mask: url('../../icons/lp-star.svg');
}
.lp-bell {
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-bell.svg');
}
.lp-double-check {
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-double-check.svg');
}
.lp-filter-noti {
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-filter-noti.svg');
}
.lp-dots{
  width: 24px;
  height: 24px;
  @extend .lp-icon;
  mask: url('../../icons/lp-dots.svg');
}
