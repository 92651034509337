.p-breadcrumb {


  ul {
    li {
      .p-menuitem-link {
        .p-menuitem-text {
          color: $text-secondary;
        }
      }
    }
  }
}
