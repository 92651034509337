.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 16px 0;
    }
  }

  .p-accordion-tab {
    box-shadow: none;

    .p-accordion-toggle-icon {
      order: 0;
      margin-left: 0;
    }
  }
}

//
.accordion-header-text {
  color: $primary-alternative-dark;
  font-size: 18px;
  font-weight: 400;
}
