.p-autocomplete {
  .p-autocomplete-multiple-container {
    padding: 0 1rem;
    height: 42.8px;

    &:not(.p-disabled) {
      &:hover {
        border-color: $info-main;

      }

      &.p-focus {
        border-color: $info-main;
        box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
      }
    }

    .p-autocomplete-input-token {
      padding: 0;
      height: 100%;
    }

    .p-autocomplete-token {
      color: $primary-alternative-dark;
      background: $primary-outlined-hover-background;
    }
  }
}

.p-autocomplete-panel {
  .p-autocomplete-items {
    .p-autocomplete-item {
      &.p-highlight {
        color: $primary-alternative-dark;
        background: $primary-outlined-hover-background;
      }
    }


  }
}
