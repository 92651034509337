@import "src/assets/styles/variable";

//.p-treetable {
.p-treetable {
  .p-datatable-wrapper {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  .p-treetable-thead {
    & > tr {
      & > th {
        text-align: left;
        padding: 1rem 1rem;
        font-size: 14px;
        color: $primary-alternative-dark;
        border-width: 0 0 0 0;
        font-weight: 600;
        background: $action-hover;
        transition: none;
        position: relative;

        &:after {
          content: '';
          width: 2px;
          height: 14px;
          background-color: $action-disabled;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .p-treetable-tbody {

    & > tr {
      cursor: pointer;

      &.p-highlight {
        background: transparent;
        color: transparent;

        td {
          background: rgba($primary-main, 0.12);
          color: $primary-main;
        }
      }

      & > td {
        vertical-align: text-top;
        padding: 0.8rem 1rem;
        align-items: center;
        color: $primary-alternative-dark;
        font-size: 16px;
        overflow-wrap: anywhere;
        overflow: hidden;

        & > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &.not-hoverable {
        &:hover {
          td {
            background-color: transparent;
          }
        }
      }

      &:hover {
        td {
          background: rgba($primary-main, 0.06);
        }
      }
    }

  }
}

//}
