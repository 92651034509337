.p-inputswitch {
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $info-outlined-resting-background;

      &::before {
        background: $primary-contained-hover-background;
      }
    }

    &:not(.p-disabled) {
      &:hover {
        .p-inputswitch-slider {
          background: $info-outlined-resting-background;
        }
      }
    }
  }
}
