.p-overlaypanel {
  &.bdrs-16 {
     -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
  }

  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  &::before, &::after {
    display: none;
  }

  .p-overlaypanel-content {
    padding: .5rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
