.p-menu {
  &.p-menu-overlay {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    padding: 1rem;
  }

  .p-menuitem-link {
    padding: 10px 0 5px;
    border-bottom: 1px solid $base-bg-2;

    .p-menuitem-text {
      color: $text-secondary;
      font-size: 14px;
    }
  }
}
