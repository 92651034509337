/* Color styles */
$white: #FFFFFF !default;

$transparent: transparent !default;


$primary-main: #1D8ED0 !default;
$primary-alternative-light: #00A1FF !default;
$primary-alternative-dark: #083761 !default;
$primary-contained-hover-background: #00A1FF !default;
$primary-outlined-hover-background: #D1EEFF !default;
$primary-outlined-resting-background: #89D3FF !default;

$info-main: #16B1FF !default;
$info-alternative-light: #32BAFF !default;
$info-alternative-dark: #0E71A3 !default;
$info-contained-hover-background: #139CE0 !default;
$info-outlined-hover-background: #CAEDFF !default;
$info-outlined-resting-background: #93DBFF !default;

$success-main: #56CA00 !default;
$success-alternative-light: #6AD01F !default;
$success-alternative-dark: #378100 !default;
$success-contained-hover-background: #4CB200 !default;
$success-outlined-hover-background: #CEFFAA !default;
$success-outlined-resting-background: #BEFA92 !default;

$warning-main: #FFB400 !default;
$warning-alternative-light: #FFB547 !default;
$warning-alternative-dark: #A37300 !default;
$warning-contained-hover-background: #E09E00 !default;
$warning-outlined-hover-background: #FFE7AC !default;
$warning-outlined-resting-background: #FFD776 !default;

$error-main: #FF4C51 !default;
$error-alternative-light: #FF6166 !default;
$error-alternative-dark: #A33134 !default;
$error-contained-hover-background: #E04347 !default;
$error-outlined-hover-background: #FFD9DA !default;
$error-outlined-resting-background: #FF9094 !default;

$text-primary: #3A3541 !default;
$text-secondary: #605E62 !default;
$text-disabled: #B3B3B3 !default;

$action-active: #54505A !default;
$action-hover: #F5FCFF !default;
$action-selected: #8F8C93 !default;
$action-disabled: #E7E7E7 !default;
$action-disabled-b-g: #F3F3F3 !default;

$other-divider: #E0E0E0 !default;
$other-outline-border: #DDDDDD !default;
$other-input-line: #C8C8C8 !default;
$other-overlay: #888888 !default;

$custom-b-g-secondary: #8A8D93 !default;
$custom-b-g-info: #2196F3 !default;
$custom-b-g-success: #4CAF50 !default;
$custom-b-g-warning: #ED6C02 !default;
$custom-b-g-error: #F44336 !default;

$base-bg-2: #E8EAF6 !default;

/* Text-size styles */
$chip: 13px !default;
$button: 14px !default;
$body: 16px !default;
$heading-h1: 96px !default;
$heading-h2: 60px !default;
$heading-h3: 48px !default;
$heading-h4: 34px !default;
$heading-h5: 24px !default;
$heading-h6: 20px !default;
$table-header: 14px !default;
$tooltip: 11px !default;
$input-text: 16px !default;
$input-notify: 12px !default;
$input-label: 14px !default;
$subtitle: 18px !default;

/* menu size */
$top-bar-height: 0px;
$left-menu-width: 306px;
$left-menu-minimized-width: 90px;
