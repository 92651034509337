.p-dialog-body {
  margin: 0 !important;
  flex: 1 1 auto;
  overflow: auto;
  padding: 0.5rem 0.5rem;
}

.p-dialog {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;

  .p-dialog-header {
    padding: .5rem 1rem !important;
    //border-bottom: 1px solid $primary-main;

    .p-dialog-title {
      font-size: 20px;
      font-weight: 500;
      color: $primary-alternative-dark;
    }
  }

  .p-dialog-content {
    padding: 0;
    display: flex;
    flex-direction: column;

    .p-confirm-dialog-message {
      padding: 1rem 2rem;
      color: $primary-alternative-dark;
    }
  }

  .p-dialog-footer {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
