.p-timeline-left {
  .p-timeline-event-opposite {
    display: none;
  }
  .p-timeline-event-content {
    p {
      color: $text-primary;
      margin: 0;
    }
  }
}

.p-timeline {
  .p-timeline-event-marker {
    background-color: $info-main;
    width: 0.95rem;
    height: 0.95rem;
  }

  .inner-dot {
    height: 12px;
    width: 12px;
    background-color: $info-main;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .outter-dot {
    width: 18px;
    height: 18px;
    background: $info-outlined-hover-background;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  &.p-timeline-vertical {
    .p-timeline-event-connector {
      width: 1px;
      background-color: rgba(58, 53, 65, 0.12);
      margin: .65rem 0;
    }
  }
}

.p-timeline-event-separator {
  padding-top: 0.2rem;
}

