.p-tag {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  font-weight: 400;
  padding: 0.25rem 10px;
  &.success {
    color: $success-main;
    background: $success-outlined-hover-background;
    border: 1px solid $success-main;
  }
  &.danger {
    color: $error-main;
    background: $error-outlined-hover-background;
    border: 1px solid $error-main;
  }
  &.warning  {
    color: $warning-main;
    background: $warning-outlined-hover-background;
    border: 1px solid $warning-main;
  }
  &.sky {
    color: $white;
    background: $primary-main;
    font-size: 13px;
  }
}
