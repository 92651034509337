@import 'src/assets/styles/variable';

.p-button {
  //box-shadow: 0px 4px 8px -4px #3A35416B;
  background: $primary-main;
  padding: 4px 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 400;
  font-size: $button;
  height: 30px;

  &.p-button-outlined {
    color: $primary-main;

    &:enabled {
      &:hover {
        background: $primary-outlined-hover-background;
        color: $primary-main;
      }

      &:active {
        background: rgba($primary-main, 0.04);
        color: $primary-main;
      }

      &:focus {
        background: rgba($primary-main, 0.04);
      }
    }
  }

  &.p-button-icon-only {
    width: 30px;
    height: 30px;
  }

  &:enabled {
    &:hover {
      background-color: rgba(29, 142, 208, 0.8);
    }

    &:focus {
      background-color: $primary-main;
    }
  }

  &.p-button-success {
    &.p-button-outlined {
      color: $success-alternative-dark;
    }
  }

  &.p-button-stop {
    &.p-button-outlined {
      color: $error-main;
    }
  }

  &.p-button-danger {
    &.p-button-outlined {
      color: $error-alternative-dark;

      &:enabled {
        &:hover {
          color: $error-alternative-dark;
        }
      }
    }
  }

  &.p-button-secondary {
    &.p-button-outlined {
      color: $primary-alternative-dark;
      border-color: $primary-alternative-dark;

      &:enabled {
        &:hover {
          color: $primary-alternative-dark;
          border-color: $primary-alternative-dark;
          background-color: $primary-outlined-hover-background;
        }

        &:focus {
          color: $primary-alternative-dark;
          border-color: $primary-alternative-dark;
          background-color: $white;
        }
      }
    }
  }

  &.p-button-cancel {
    &.p-button-outlined {
      color: $text-secondary;
      border-color: $text-secondary;

      &:enabled {
        &:hover {
          color: $text-secondary;
          border-color: $text-secondary;
          background-color: $action-hover;
        }
      }
    }
  }

  &.p-button-import {
    &.p-button-outlined {
      color: $warning-alternative-dark;
      border-color: $warning-alternative-dark;

      &:enabled {
        &:hover {
          color: $warning-alternative-dark;
          border-color: $warning-alternative-dark;
          background-color: $warning-outlined-hover-background;
        }
      }
    }
  }
}

.p-button-label {
  //text-transform: uppercase;
}

.p-splitbutton {
  &.p-button-outlined {
    > .p-button {
      color: $primary-main;
      &:enabled {
        &:hover {
          color: $primary-main;
        }
      }
    }
  }
}
.p-tieredmenu {
  min-width: 250px;
  .p-menuitem-link {
    border-bottom: 1px solid $base-bg-2;
    padding: 0 0 5px 0;
    margin: 18px;
    .p-menuitem-text {
      color: $text-secondary !important;
      display: block;
      font-size: 14px;
    }
    &:not(.p-disabled) {
      &:hover {
        .p-menuitem-text {
          color: $primary-main !important;
        }
      }
    }
  }
  &.p-tieredmenu-overlay {
    border-radius: 12px;
  }
  .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: none;
  }
  .p-menuitem-link:not(.p-disabled):hover {
    background: none;
  }
}
