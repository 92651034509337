/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/variable';
@import 'assets/styles/layout';
@import 'assets/styles/theme/index';
@import 'assets/styles/custom';
@import "assets/styles/animation";
@import "assets/styles/menu";
@import "node_modules/line-awesome/dist/line-awesome/scss/line-awesome";

@font-face {
  font-family: "SF Pro Text";
  src: url(./assets/fonts/SF-Pro/SF-Pro-Text-Regular.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url(./assets/fonts/SF-Pro/SF-Pro-Text-Medium.otf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url(./assets/fonts/SF-Pro/SF-Pro-Text-Semibold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #878c90;
}

/* Handle on hover */
