.p-multiselect {
  border-color: $other-divider;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  .p-multiselect-label {
      padding: 13px 13px;
  }
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.38);
    }
    &:hover {
      box-shadow: none;
      border-color: $info-main;
    }
  }
  width: 100% !important;
  &-item {
    margin: 0;
  }
  &.p-multiselect-chip {
    .p-multiselect-token {
      color: $primary-alternative-dark;
      background: $primary-outlined-hover-background;
    }
  }
}
.p-inputtext-sm {
  .p-multiselect-label {
    font-size: 0.875rem;
    padding: 0.875rem 0.875rem;
  }
}



.p-multiselect-panel {
  &.has-filter {
    .p-multiselect-header {
      &:after {
       display: none;
      }
    }
  }
  .p-multiselect-header {
    &:after {
      content: "Tất cả";
      position: absolute;
      left: 2.5rem;
    }
  }
  .p-multiselect-items {
    .p-multiselect-item {
      &:focus {
        &.p-highlight {
          background-color: $primary-outlined-hover-background;
        }
      }
      &.p-highlight {
        color: $info-main;
        background: $primary-outlined-hover-background;
      }
    }
  }
}
