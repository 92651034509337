.p-paginator {
  background: $transparent;
  padding: 0.5rem 0.5rem 0.5rem 0;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    height: 32px;
    min-width: 32px;
    color: $primary-alternative-dark;
  }

  .p-paginator-current {
    font-size: 13px;
    color: $primary-alternative-dark;
  }

  justify-content: flex-end !important;

  .p-dropdown {
    background: $transparent;
    align-items: center;
    height: 2rem;
    margin-left: 0px;
    margin-right: 0px;

    .p-dropdown-label {
      line-height: 22px;
    }
  }

  .p-paginator-pages {
    .p-paginator-page {
      font-size: 14px;
      height: 32px;
      min-width: 32px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      color: $primary-alternative-dark;

      &.p-highlight {
        background: $transparent;
        border: 1px solid $primary-main;
        color: $primary-main;
      }
    }
  }

}
