
.p-checkbox {
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-highlight {
        &:hover {
          background: $primary-main;
          border-color: $primary-main;
        }
      }
    }
  }

  &.p-checkbox-checked {
    &:not(.p-checkbox-disabled) {
      &.p-checkbox-focused {
        box-shadow: 0 0 1px 10px $primary-outlined-hover-background;
      }
    }
  }

  .p-checkbox-box {
    &.p-highlight {
      &:not(.p-disabled) {
        &.p-focus {
          border-color: $primary-main;
        }
      }
      background: $primary-main;
      border-color: $primary-main;
    }
  }
}

.p-checkbox-label {
  cursor: pointer;
  color: $primary-alternative-dark;
}

.p-input-filled {
  .p-checkbox {
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &.p-highlight {
          background: $info-alternative-light;

          &:hover {
            background: $info-alternative-light;
          }
        }
      }
    }

    .p-checkbox-box {
      &.p-highlight {
        background: $info-alternative-light;

        &:hover {
          background: $info-alternative-light;
        }
      }
    }
  }
}
