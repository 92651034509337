.p-rating {
  &:not(.p-disabled) {
    &:not(.p-readonly) {
      .p-rating-icon {
        &.p-rating-cancel {
          &:hover {
            color: $action-hover;
            background-color: $action-disabled;
          }
        }

        &:hover {
          background-color: $warning-outlined-hover-background;
          color: $warning-main;
        }
      }
    }
  }

  .p-rating-icon {
    &:focus {
      background: none;
    }

    color: $action-active;

    &.pi-star-fill {
      color: $warning-main;
    }

    &.p-rating-cancel {
      color: $action-hover;

      &:focus {
        background: none;
      }
    }
  }
}
