@import "src/assets/styles/variable";

p-table {
  .p-datatable {
    .p-datatable-wrapper {
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
    }

    .p-datatable-thead {
      & > tr {
        & > th {
          text-align: left;
          padding: 1rem 1rem;
          font-size: 14px;
          color: $primary-alternative-dark;
          border-width: 0 0 0 0;
          font-weight: 600;
          background: $action-hover;
          transition: none;
          position: relative;

          &:after {
            content: '';
            width: 2px;
            height: 14px;
            background-color: $action-disabled;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }
      }
    }

    .p-datatable-tbody {
      //display: flex;
      //flex-direction: column;
      //justify-content: center;
      //align-items: center;

      & > tr {
        cursor: pointer;

        &.p-highlight {
          background: transparent;
          color: transparent;

          td {
            background: rgba($primary-main, 0.12);
            color: $primary-main;
          }
        }

        & > td {
          vertical-align: middle;
          position: relative;
          padding: 0.8rem 1rem;
          align-items: center;
          color: $primary-alternative-dark;
          font-size: 16px;
          overflow-wrap: anywhere;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 300px;

          & > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &.not-hoverable {
          &:hover {
            td {
              background-color: transparent;
            }
          }
        }

        &:hover {
          td {
            background: rgba($primary-main, 0.06);
          }
        }
      }

    }

    .p-paginator-bottom {
      background: #eceff4;
      border: none;
      font-size: 12px;
    }
  }
}

.p-datatable {
  &.p-datatable-hoverable-rows {
    .p-datatable-tbody {
      & > tr {
        &:not(.p-highlight) {
          &:hover {
            background: $white;
          }
        }
      }
    }
  }

  .p-datatable-tbody {
    & > tr {
      &:not(.p-highlight) {
        &:focus {
          background-color: $white;
        }
      }
    }
  }
}

.p-datatable-scrollable-table {
  & > .p-datatable-frozen-tbody {
    tr {
      &:last-of-type {
        td {
          border-bottom: 1px solid $primary-main;
        }
      }
    }
  }
}
